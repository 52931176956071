<template>
  <v-dialog v-model="internalValue" max-width="1200px">
    <v-card>
      <v-card-title
        class="text-h5 d-flex justify-space-between"
        style="background-color: #cc2128; color: white"
      >
        Actualizar Cliente FE
        <v-btn icon @click="closeDialog" style="color: white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                ref="NombreCompania"
                v-model="dataClient.NombreCompania"
                label="Cliente"
                prepend-inner-icon="mdi-account-tie"
                clearable
                :rules="formRules"
                :error-messages="errorMessages"
                required
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                ref="Estado"
                v-model="dataClient.Estado"
                :items="typeEnvironment"
                item-text="textEnvironment"
                item-value="valueEnvironment"
                label="Ambiente"
                prepend-inner-icon="mdi-sitemap"
                :rules="formRules"
                :error-messages="errorMessages"
                required
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                ref="RefProveedor"
                v-model="dataClient.ProveedorTecnologico"
                :items="listTechnologyProvider"
                item-text="textEnvironment"
                item-value="valueEnvironment"
                label="Proveedor Tecnológico"
                prepend-inner-icon="mdi-file-chart"
                :rules="formRules"
                :error-messages="errorMessages"
                required
                :disabled="true"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                ref="Url"
                v-model="dataClient.Url"
                label="Url Proveedor Tecnológico"
                prepend-inner-icon="mdi-account-network"
                clearable
                :rules="formRules"
                :error-messages="errorMessages"
                required
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                ref="TokenEnterprise"
                v-model="dataClient.TokenEnterprise"
                label="Token Enterprise"
                prepend-inner-icon="mdi-account-key"
                clearable
                :rules="formRules"
                :error-messages="errorMessages"
                :disabled="isEDNProvider"
                required
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                ref="TokenPassword"
                v-model="dataClient.TokenPassword"
                label="Token Password"
                prepend-inner-icon="mdi-key"
                clearable
                :rules="formRules"
                :error-messages="errorMessages"
                :disabled="isEDNProvider"
                required
              />
            </v-col>
          </v-row>

          <v-row v-if="isEDNProvider">
            <v-col cols="12" sm="6">
              <v-text-field
                ref="client_id"
                v-model="client_id"
                label="Client ID"
                prepend-inner-icon="mdi-chevron-double-right"
                :rules="formRules"
                required
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                ref="client_secret"
                v-model="client_secret"
                label="Client secret"
                prepend-inner-icon="mdi-chevron-double-right"
                :rules="formRules"
                required
                clearable
              />
            </v-col>
          </v-row>

          <v-row v-if="isEDNProvider">
            <v-col cols="12" sm="2">
              <v-text-field
                ref="grant_type"
                v-model="grant_type"
                label="Grant Type"
                prepend-inner-icon="mdi-chevron-double-right"
                :disabled="true"
              />
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field
                ref="url_token"
                v-model="url_token"
                label="URL token"
                prepend-inner-icon="mdi-chevron-double-right"
                :rules="formRules"
                required
                clearable
              />
            </v-col>

            <v-col cols="12" sm="5">
              <v-text-field
                ref="scope"
                v-model="scope"
                label="Scope"
                prepend-inner-icon="mdi-chevron-double-right"
                :rules="scopeFormRules"
                required
                clearable
              />
            </v-col>
          </v-row>

          <v-row v-if="isEDNProvider">
            <v-col cols="12" sm="6">
              <v-text-field
                ref="Emisor"
                v-model="emisor"
                label="Emisor"
                prepend-inner-icon="mdi-chevron-double-right"
                type="number"
                clearable
                :rules="emisorFormRules"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                ref="Representation"
                v-model="representation_graphic"
                :items="representationItems"
                item-value="value"
                label="Representación gráfica"
                prepend-inner-icon="mdi-image"
                :rules="formRules"
                required
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                ref="NitCliente"
                v-model="dataClient.NitCliente"
                label="Nit"
                prepend-inner-icon="mdi-pencil-box-outline"
                type="number"
                clearable
                :rules="formRules"
                :error-messages="errorMessages"
                required
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                ref="EmailFE"
                v-model="dataClient.EmailFE"
                label="Email Envío FE No Procesadas"
                prepend-inner-icon="mdi-email"
                :rules="emailRules"
                required
                clearable
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                ref="EmailDelivery"
                v-model="dataClient.EmailDelivery"
                label="Email Delivery"
                prepend-inner-icon="mdi-email"
                :rules="emailDeliveryRules"
                required
                clearable
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-alert
        v-if="showSuccessAlert"
        type="success"
        color="green"
        dark
        class="mt-4"
        @input="showSuccessAlert = false"
      >
        Los datos se actualizaron con éxito
      </v-alert>

      <v-card-actions class="justify-end">
        <v-btn color="#cc2128" dark @click="updateClient"> Actualizar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
import { mapState } from "vuex";

export default {
  name: "ShowUpdateClients",
  props: {
    value: Boolean,
    client: Object,
    typeEnvironment: Array,
    listTechnologyProvider: Array,
    client_id: String,
    client_secret: String,
    grant_type: String,
    scope: String,
    url_token: String,
    emisor: String,
    representation_graphic: Array,
    idCompania: String,
  },
  data() {
    return {
      internalValue: this.value,
      originalDataClient: {},
      showSuccessAlert: false,
      dataClient: {
        NombreCompania: this.client?.NombreCompania || "",
        Estado: this.client?.Estado || "",
        ProveedorTecnologico: this.client?.Proveedor || "",
        Url: this.client?.Url || "",
        TokenEnterprise: this.client?.TokenEnterprise || "",
        TokenPassword: this.client?.TokenPassword || "",
        NitCliente: this.client?.NitCliente || "",
        EmailFE: this.client?.EmailFE || "",
        EmailDelivery: this.client?.EmailDelivery || "",
      },
      typeEnvironment: [],
      representationItems: [
        { text: "Standard", value: "Standard" },
        //{ text: "Plus", value: "Plus" },
      ],
      errorMessages: "",
      formRules: [(v) => !!v || "Este campo es requerido"],
      emailRules: [
        (v) => !!v || "Este campo es requerido",
        (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
      ],
      emisorFormRules: [
        (v) => !!v || "Este campo es requerido",
        (v) => v > 0 || "Este campo no acepta números negativos",
      ],
      client_id: "",
      client_secret: "",
      grant_type: "",
      url_token: "",
      scope: "",
      emisor: "",
      representation_graphic: [],
      scopeFormRules: [
        (v) => !!v || "Este campo es requerido",
        (v) => /^https?:\/\/.+/i.test(v) || "El valor debe ser una url valida",
      ],
      emailDeliveryRules: [
        (v) => !v || /.+@.+\..+/.test(v) || "Correo inválido",
      ],
      client_id: "",
      client_secret: "",
    };
  },
  computed: {
    isEDNProvider() {
      return this.dataClient.ProveedorTecnologico === "EDN";
    },
    ...mapState("clients", ["updateUserResponse"]),
    updateUserResponse() {
      return this.$store.state.clients.updateUserResponse;
    },
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit("input", val);
    },
    client: {
      handler(newClient) {
        this.dataClient = {
          NombreCompania: newClient?.NombreCompania || "",
          Estado: newClient?.Estado || "",
          ProveedorTecnologico: newClient?.Proveedor || "",
          Url: newClient?.Url || "",
          TokenEnterprise: newClient?.TokenEnterprise || "",
          TokenPassword: newClient?.TokenPassword || "",
          NitCliente: newClient?.NitCliente || "",
          EmailFE: newClient?.EmailFE || "",
          EmailDelivery: newClient?.EmailDelivery || "",
        };
        this.originalDataClient = { ...this.dataClient };
      },
      immediate: true,
    },
    updateUserResponse() {
      if (this.updateUserResponse === true) {
        this.generateAlertSuccess();
        this.$store.dispatch("clients/setUpdateResponseUser", false);
      }
    },
    "dataClient.ProveedorTecnologico"(newValue) {
      if (newValue === "EDN") {
        this.grant_type = "client_credentials";
      } else {
        this.grant_type = "";
      }
    },
    client_id(newValue) {
      if (this.isEDNProvider) {
        this.dataClient.TokenEnterprise = `EDN_TE-${newValue}`;
      }
    },
    client_secret(newValue) {
      if (this.isEDNProvider) {
        this.dataClient.TokenPassword = `EDN_TP-${newValue}`;
      }
    },
  },
  methods: {
    closeDialog() {
      this.dataClient = { ...this.originalDataClient };
      this.internalValue = false;
    },
    getProveedorValue(proveedor) {
      return proveedor === "HKA" ? "1" : proveedor === "EDN" ? "2" : null;
    },
    async updateClient() {
      if (this.$refs.form.validate()) {
        this.originalDataClient = { ...this.dataClient };
        const updatedClientData = {
          ...this.dataClient,
          IdCompania: this.idCompania,
          InformacionAdicional: null,
          ProveedorTecnologico: this.getProveedorValue(
            this.dataClient.ProveedorTecnologico
          ),
        };

        let InformacionAdicional = {
          grant_type: this.grant_type,
          client_id: this.client_id,
          client_secret: this.client_secret,
          scope: this.scope,
          url_token: this.url_token,
          emisor: this.emisor,
          representation_graphic: this.representation_graphic,
        };

        if (updatedClientData.ProveedorTecnologico === "2") {
          updatedClientData.UrlApiGestion = "URL_EDN...";
        } else if (updatedClientData.ProveedorTecnologico === "1") {
          if (this.dataClient.Estado === "P") {
            updatedClientData.UrlApiGestion =
              "https://gestioncontribuyentesrest.thefactoryhka.com.co/api";
          } else {
            updatedClientData.UrlApiGestion =
              "https://demogestioncontribuyentesrest.thefactoryhka.com.co/api";
          }
        }

        if (this.isEDNProvider) {
          updatedClientData.InformacionAdicional =
            JSON.stringify(InformacionAdicional);
        }

        const user = JSON.parse(atob(localStorage.getItem("uid"))).user;
        updatedClientData.user = user;

        try {
          await this.$store.dispatch("clients/updateUser", updatedClientData);

          this.showSuccessAlert = true;

          setTimeout(() => {
            this.showSuccessAlert = false;
            this.$emit("client-updated");
          }, 2000);
        } catch (error) {
          console.error("Error actualizando cliente:", error);
        }
      }
    },
  },
};
</script>
  
  <style scoped>
</style>  